import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { MetadataModel } from 'src/server/models/metadata';


export class ProductsActions {
  static products = {
    request: createAction('products/products_request', resolve => (payload: ProductsPayload) =>
      resolve(payload),
    ),
    success: createAction('products/products_success', resolve => (payload: { products: ProductModel[], attributes: AttributeModel[], total_count: string }) =>
      resolve(payload),
    ),
    fail: createAction('products/products_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static updateMetadata = {
    request: createAction('products/metadata_category_request', resolve => (payload: MetadataModel) =>
      resolve(payload),
    ),
    success: createAction('products/metadata_category_success'),
    fail: createAction('products/metadata_category_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static setProductMetadataModal = createAction('products/metadata_modal', resolve => (payload: boolean) =>
    resolve(payload),
  );

  static openProductMetadataModal = createAction('products/show_metadata_modal', resolve => () =>
    resolve(),
  );

  static closeProductMetadataModal = createAction('products/show_metadata_modal', resolve => () =>
    resolve(),
  );

  static setMetadata = createAction('products/metadata', resolve => (payload: any) =>
    resolve(payload),
  );
  static setSelectedProduct = createAction('products/set_selected_product', resolve => (payload: ProductModel) =>
    resolve(payload),
  );
}