import { Helmet } from 'react-helmet-async';
import { Grid, Container, Alert } from '@mui/material';
import Footer from 'src/components/Footer';
import { useEffect } from 'react';
import { RootState } from 'src/store/rootState';
import { useSelector } from 'react-redux';
import { useAction } from 'src/utils/hooks/action';
import { useParams } from 'react-router';
import React from 'react';
import { OfferActions } from './module/actions';
import AssignCategoryModal from './components/AssignCategoryModal';
import AssignProductModal from './components/AssignProductModal';
import OfferDetails from './components/OfferDetails';
import OfferCategories from './components/OfferCategories';
import OfferProducts from './components/OfferProducts';
import OfferHeader from './components/OfferHeader';

function OfferPage() {
  const { loading, categories, products } = useSelector((state: RootState) => state.coupon);
  const offerAction = useAction(OfferActions.offer.request);
  const { id } = useParams();
  
  useEffect(() => {
    console.log("id received");
    console.log(id);
    if (id) {
      offerAction({id: id});
    }
  }, [id])
  
  return (
    <>
      <Helmet>
        <title>Offer #{id}</title>
      </Helmet>
      <OfferHeader title={'Offer #' + id} description="" />

      { loading && <Container maxWidth="lg"><div>Loading...</div></Container>}
      { !loading && <Container maxWidth="lg">
      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
          {(!loading && categories.length == 0 && products.length == 0) && <Alert style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 14 }} severity="error">OFFER IS NOT ASSIGNED TO ANY PRODUCT</Alert>}
          </Grid>
          <Grid item xs={12}>
            <OfferDetails />
          </Grid>
        </Grid>

        <div style={{height: 20}}/>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <OfferCategories />
          </Grid>
        </Grid>

        <div style={{height: 20}}/>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <OfferProducts />
          </Grid>
        </Grid>

        {/* <AcceptOrderModal/>
        <RejectOrderModal/>
        <CompleteOrderModal/> */}
        <AssignCategoryModal/>
        <AssignProductModal/>
      </Container>}
      <Footer />
    </>
  );
}

export default OfferPage;
