import React from 'react';
import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import axios from 'axios';
import { HomeApis } from '../../../server/api/Home';
import { CouponActions } from './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { CouponsApis } from 'src/server/api/Coupons';


function* coupon({ payload }: ActionType<typeof CouponActions.coupon.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { data } = yield* call(CouponsApis.coupon, payload) as any;
    console.log('coupons response: ');
    console.log(data);
    yield* put(CouponActions.coupon.success(data.data)) as any;
  } catch (error) {
    console.log(error);
    yield* put(CouponActions.coupon.fail('Something went wrong while trying to load coupons')) as any;
  }
}

function* categoriesToAssign(action: ActionType<typeof CouponActions.categoriesToAssign.request>): SagaIterator {
  try {
    console.log('request categories saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AppApis.categories) as any;
      console.log('categories response: ');
      console.log(data);
      yield* put(CouponActions.categoriesToAssign.success(data.data)) as any;
  } catch (error) {
    yield* put(CouponActions.categoriesToAssign.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

function* assignCategory({ payload }: ActionType<typeof CouponActions.assignCategory.request>): SagaIterator {
  try {
    console.log('request assign category saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CouponsApis.assignCategory, payload) as any;
      console.log('assign category response: ');
      console.log(data);
      yield* put(CouponActions.assignCategory.success()) as any;

      const couponResponse = yield* call(CouponsApis.coupon, { id: payload.categoryId}) as any;
      yield* put(CouponActions.coupon.success(couponResponse.data.data)) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      console.log(error.response.data['messsage']);
      yield* put(CouponActions.assignCategory.fail(error.response.data['messsage'])) as any;
    } else {
      yield* put(CouponActions.assignCategory.fail("Something went wrong while trying to assign category to coupon, Please try again later.")) as any;
    }
  }
}

function* assignProduct({ payload }: ActionType<typeof CouponActions.assignProduct.request>): SagaIterator {
  try {
    console.log('request assign product saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(CouponsApis.assignProduct, payload) as any;
      console.log('assign product response: ');
      console.log(data);
      yield* put(CouponActions.assignProduct.success()) as any;

      const couponResponse = yield* call(CouponsApis.coupon, { id: payload.couponId}) as any;
      yield* put(CouponActions.coupon.success(couponResponse.data.data)) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      console.log(error.response.data['messsage']);
      yield* put(CouponActions.assignProduct.fail(error.response.data['messsage'])) as any;
    } else {
      yield* put(CouponActions.assignProduct.fail("Something went wrong while trying to assign product to coupon, Please try again later.")) as any;
    }
  }
}

export function* watchCoupon(): SagaIterator {
  yield* takeLatest(CouponActions.coupon.request, coupon) as any;
  yield* takeLatest(CouponActions.categoriesToAssign.request, categoriesToAssign) as any;
  yield* takeLatest(CouponActions.assignCategory.request, assignCategory) as any;
  yield* takeLatest(CouponActions.assignProduct.request, assignProduct) as any;
}
