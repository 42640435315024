import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call } from 'typed-redux-saga';

import './actions';
import { AppActions } from '../../../modules/app/actions';
import { OffersActions } from './actions';
import { OffersApis } from 'src/server/api/Offers';
import moment from 'moment';

function* offers(): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;

    const { data } = yield* call(OffersApis.offers) as any;
    console.log('offers response: ');
    console.log(data);
    yield* put(OffersActions.offers.success(data.data)) as any;
  } catch (error) {
    console.log(error);
    yield* put(OffersActions.offers.fail('Something went wrong while trying to load coupons')) as any;
  }
}

function* create({ payload }: ActionType<typeof OffersActions.create.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    const body = {
      offer: {
        name: payload.name,
        description: payload.description,
        price: !payload.price ? 0 : +payload.price,
        start_date: (moment(payload.start_date)).format('YYYY-MM-DD HH:mm:ss'),
        end_date: (moment(payload.end_date)).format('YYYY-MM-DD HH:mm:ss'),
        is_active: false,
        subtotal_minimum_amount: !payload.subtotal_minimum_amount ? 0 : +payload.subtotal_minimum_amount
      }
    };
    console.log('coupon payload');
    console.log(payload);
    const { data } = yield* call(OffersApis.create, body) as any;
    console.log('coupons response: ');
    console.log(data);
    yield* put(OffersActions.create.success()) as any;

    // Request coupons list
    const offersResponse = yield* call(OffersApis.offers) as any;
    yield* put(OffersActions.offers.success(offersResponse.data.data)) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status === 400) {
      console.log(error.response.data.data['messsage']);
      yield* put(OffersActions.create.fail(error.response.data.data['messsage'])) as any;
    } else {
      yield* put(OffersActions.create.fail("Server Error, Something went wrong while trying to create coupon, Please try again later")) as any;
    }
  }
}

export function* watchOffers(): SagaIterator {
  yield* takeLatest(OffersActions.offers.request, offers) as any;
  yield* takeLatest(OffersActions.create.request, create) as any;
}
