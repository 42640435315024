import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HiPhoneLogoImage from '../../assets/hiphone-logo.png'
import { useEffect, useState } from 'react';
import { RootState } from 'src/store/rootState';
import { useSelector } from 'react-redux';
import { useAction } from 'src/utils/hooks/action';
import { AuthActions } from './module/actions';
import { useNavigate } from 'react-router';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        HiPhone Telecom
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#f44336',
      },
      secondary: {
        main: '#f44336',
      },
    },
  } as any);

export default function LoginPage() {
  const navigate = useNavigate();
  
  const [loginUsername, setLoginUsername] = useState<string>('');
  const [loginPassword, setLoginPassword] = useState<string>('');
  const [registerEmail, setRegisterEmail] = useState<string>('');
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const { login } = useSelector((state: RootState) => state.app);
  const loginAction = useAction(AuthActions.login.request);
  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    loginAction({username: loginUsername, password: loginPassword})
  };

  useEffect(() => {
    if (login) {
      navigate("/orders", { replace: true });
    }
  }, [login])

  if(loading) {
    return <>Authenticating..., Please wait</>
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" style={{height: '100%', display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'}}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f1f1f1f1',
            borderRadius: 8,
            border: '1px solid #DDDDDD',
            padding: 10
          }}
        >
          <img src={HiPhoneLogoImage}/>
          <div style={{height: 5}}/>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              variant="filled"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{borderRadius: 8}}
              value={loginUsername}
              onChange={(event) => {
                setLoginUsername(event.target.value)
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              variant="filled"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{borderRadius: 8}}
              value={loginPassword}
              onChange={(event) => {
                setLoginPassword(event.target.value)
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 4, height: 52, backgroundColor: 'black', color: 'white' }}
            >
              Sign In
            </Button>
            
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}