import {
  Grid,
  CircularProgress,
  Alert,
  CardHeader,
  Divider,
  Box,
  TextField,
  MenuItem,
  TextFieldProps,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FilledInput
} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useAction } from 'src/utils/hooks/action';
import { ReactElement, JSXElementConstructor, useState, useEffect } from 'react';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { OfferActions } from '../module/actions';
import { AssignCategoryToCouponPayload } from 'src/server/payload/assign_category_to_coupon';
import { AssignCategoryToOfferPayload } from 'src/server/payload/assign_category_to_offer';

function AssignCategoryModal() {
  const { assignCategoryToOfferModal, offer, showAssignCategoryModal } = useSelector((state: RootState) => state.offer);
  const setAssignCategoryToOfferModal = useAction(OfferActions.assignCategoryToOfferModal);
  const requestCategories = useAction(OfferActions.categoriesToAssign.request);
  const assignCategory = useAction(OfferActions.assignCategory.request);
  const setShowAssignCategory = useAction(OfferActions.showAssignCategoryModal);

  useEffect(() => {
    if(showAssignCategoryModal) {
      requestCategories();
    }
  }, [showAssignCategoryModal])

  const handleClose = () => {
    if (assignCategoryToOfferModal.loading) {
      return;
    }
    setShowAssignCategory(false)
  }

  if(!assignCategoryToOfferModal) {
    return <></>
  }

  return (
    <Dialog onClose={handleClose} open={showAssignCategoryModal ?? false}>
      {<List sx={{ pt: 0 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          {
          assignCategoryToOfferModal.loading && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: 20}}>
            <CircularProgress />
            </div>
            }
          { assignCategoryToOfferModal.loading || <Grid item xs={12}>
            <div>
              <CardHeader title="Assign Category To The Offer" />
              <Divider />
              <div style={{ marginTop: 8, marginBottom: 8, marginRight: 18 }}>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off"
                > 

                  <TextField
                    id="filled-select-currency-native"
                    select
                    style={{ width: '100%' }}
                    label="Select discount type"
                    variant="filled"
                    disabled={assignCategoryToOfferModal.loading}
                    value={assignCategoryToOfferModal.selectedCategoryId}
                    onChange={(event) => {
                      setAssignCategoryToOfferModal({ ...assignCategoryToOfferModal, selectedCategoryId: event.target.value })
                    }}
                  >
                    {(assignCategoryToOfferModal.categories ?? []).map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                      {category.name_en}
                    </MenuItem>
                    ))}
                  </TextField>

                </Box>
              </div>
              <Divider />
              {(assignCategoryToOfferModal.error ?? '').length > 0 && <Alert style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, marginTop: 14 }} severity="error">{assignCategoryToOfferModal.error}</Alert>}
              { <div style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
                {!assignCategoryToOfferModal.loading && <Button
                  color='error'
                  variant="outlined"
                  onClick={() => {
                    const payload = {
                      categoryId: assignCategoryToOfferModal.selectedCategoryId, 
                      offerId: offer.id
                    } as AssignCategoryToOfferPayload
                    console.log(
                      payload
                    )
                    assignCategory(payload)
                  }}
                >Submit
                </Button>
                }
                {assignCategoryToOfferModal.loading && <CircularProgress />}
              </div>}
            </div>
          </Grid>}
        </Grid>
      </List>
      }
    </Dialog>
  );
}

export default AssignCategoryModal;