import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { ProductsActions } from './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { RootState } from '../../../store/rootState';


function* products({ payload }: ActionType<typeof ProductsActions.products.request>): SagaIterator {
  try {
    console.log('products payload');
    console.log(payload);
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { page } = yield* select((state: RootState) => state.products) as any;
    const { data } = yield* call(ProductsApis.products, payload) as any;
    console.log('products response: ');
    console.log(data);
    yield* put(ProductsActions.products.success({
      products: data.data.products,
      attributes: data.data.filters,
      total_count: data.data.total_count
    })) as any;
  } catch (error) {
    console.log(error);
    yield* put(ProductsActions.products.fail('')) as any;
  }
}

export function* watchProducts(): SagaIterator {
  yield* takeLatest(ProductsActions.products.request, products) as any;
}
