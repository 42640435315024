import React from 'react';
import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  CategoriesActions,
} from './actions';
import { AttributeModel } from '../../../server/models/attribute';
import { CategoriesTypes } from './types';

const initialState: CategoriesTypes = {
  categories: [],
  loading: false,
  loadingCreateCategory: false,
  createCategoryModal: false,
  createCategoryModalName: '',
  createCategoryModalNameAr: '',
  createCategoryErrorMessage: '',
  metadataCategoryModal: false,
  loadingMetadataCategoryModal: false,
  metadataCategoryErrorMessage: '',
  metadata: {
    name: '',
    image: '',
    url: '',
    title: '',
    canonical: '',
    keywords: '',
    description: '',
    date: '',
    sitecode: '',
    twitter: {
      card: '',
      site: '',
      creator: '',
      url: '',
      title: '',
      description: '',
      image: ''
    },
    og: {
      url: '',
      image: '',
      type: '',
      site_name: '',
      locale: '',
      title: '',
      description: '',
      country_name: ''
    }
  },
  selectedCategory: {
    id: '',
    name: '',
    name_en: '',
    image: '',
    product_id: '',
    category_id: '',
    sub_categories: [],
    metadata: ''
  }
};

export const categoriesReducer = createReducer(initialState, handleAction => [
  handleAction(CategoriesActions.categories.request, (state) =>
    produce(state, draft => {
      draft.loading = true;
      draft.categories = [];
    }),
  ),
  handleAction(CategoriesActions.categories.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.categories = payload;
    }),
  ),
  handleAction(CategoriesActions.categories.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Create category
  handleAction(CategoriesActions.create.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCreateCategory = true;
    }),
  ),
  handleAction(CategoriesActions.create.success, (state) =>
    produce(state, draft => {
      draft.loadingCreateCategory = false;
      draft.createCategoryModal = false
    }),
  ),
  handleAction(CategoriesActions.create.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryErrorMessage = payload
      draft.loadingCreateCategory = false;
    }),
  ),

  // Update metadata
  handleAction(CategoriesActions.updateMetadata.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingMetadataCategoryModal = true;
    }),
  ),
  handleAction(CategoriesActions.updateMetadata.success, (state) =>
    produce(state, draft => {
      draft.loadingMetadataCategoryModal = false;
      draft.metadataCategoryModal = false
    }),
  ),
  handleAction(CategoriesActions.updateMetadata.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.metadataCategoryErrorMessage = payload
      draft.loadingMetadataCategoryModal = false;
    }),
  ),

  // Create User Modal
  handleAction(CategoriesActions.createCategoryModel, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryModal = payload;
      draft.createCategoryModalName = ''
    }),
  ),
  
  // Fields
  handleAction(CategoriesActions.setCreateCategoryModelName, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryModalName = payload;
    }),
  ),

  handleAction(CategoriesActions.setCreateCategoryModelNameAr, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryModalNameAr = payload;
    }),
  ),
  
  handleAction(CategoriesActions.reset, (state, { payload }: any) =>
    produce(state, draft => {
      draft.metadataCategoryModal = false
      draft.loadingMetadataCategoryModal = false
      draft.loadingCreateCategory = false
      draft.createCategoryModal = false
      draft.createCategoryModalName = ''
      draft.createCategoryModalNameAr = ''
      draft.createCategoryErrorMessage = ''
      draft.metadataCategoryErrorMessage = ''
    }),
  ),

  handleAction(CategoriesActions.setMetadata, (state, { payload }: any) =>
    produce(state, draft => {
      draft.metadata = payload;
    }),
  ),
  handleAction(CategoriesActions.setCategoryMetadataModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.metadataCategoryModal = payload;
    }),
  ),

  handleAction(CategoriesActions.openCategoryMetadataModal, (state, action: any) =>
    produce(state, draft => {
      draft.metadataCategoryModal = true;
    }),
  ),
  handleAction(CategoriesActions.closeCategoryMetadataModal, (state, action: any) =>
    produce(state, draft => {
      draft.metadataCategoryModal = false;
    }),
  ),

  handleAction(CategoriesActions.setSelectedCategory, (state, { payload }: any) =>
    produce(state, draft => {
      draft.selectedCategory = payload;
    }),
  ),
]);
