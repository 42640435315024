import { createAction } from 'deox';
import { ProductModel } from '../../../server/models/product';
import { CategoryModel } from 'src/server/models/category';
import { OfferPayload } from 'src/server/payload/offer';
import { OfferModel } from 'src/server/models/offer';
import { AssignCategoryToOffer } from 'src/server/models/assign_category_to_offer';
import { AssignCategoryToOfferPayload } from 'src/server/payload/assign_category_to_offer';
import { AssignProductToOffer } from 'src/server/models/assign_product_to_offer';
import { AssignProductToOfferPayload } from 'src/server/payload/assign_product_to_offer';


export class OfferActions {
  static offer = {
    request: createAction('offer/offer_request', resolve => (payload: OfferPayload) =>
      resolve(payload),
    ),
    
    success: createAction('offer/offer_success', resolve => (payload: { offer: OfferModel, products: ProductModel[], categories: CategoryModel[] }) =>
      resolve(payload),
    ),

    fail: createAction('offer/offer_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Fetch available categories
  static categoriesToAssign = {
    request: createAction('offer/categories_to_assign_request'),
    
    success: createAction('offer/categories_to_assign_success', resolve => (payload: CategoryModel[]) =>
      resolve(payload),
    ),

    fail: createAction('offer/categories_to_assign_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Update Assign Category Modal State
  static assignCategoryToOfferModal = createAction('offer/assign_category_to_offer_modal', resolve => (payload: AssignCategoryToOffer) => resolve(payload));

  // Assign category to offer
  static assignCategory = {
    request: createAction('offer/assign_category_request', resolve => (payload: AssignCategoryToOfferPayload) =>
      resolve(payload),
    ),
    
    success: createAction('offer/assign_category_success'),

    fail: createAction('offer/assign_category_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
  
  // Update Assign Product Modal State
  static assignProductToOfferModal = createAction('offer/assign_product_to_offer_modal', resolve => (payload: AssignProductToOffer) => resolve(payload));

  // Assign category to offer
  static assignProduct = {
    request: createAction('offer/assign_product_request', resolve => (payload: AssignProductToOfferPayload) =>
      resolve(payload),
    ),
    
    success: createAction('offer/assign_product_success'),
    
    fail: createAction('offer/assign_product_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Update Assign Category Modal State
  static showAssignCategoryModal = createAction('offer/show_assign_category_to_offer_modal', resolve => (payload: boolean) => resolve(payload));
  
  // Update Assign Product Modal State
  static showAssignProductModal = createAction('offer/show_assign_product_to_offer_modal', resolve => (payload: boolean) => resolve(payload));

}
