import { FC, ChangeEvent } from 'react';
import React from 'react';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Button
} from '@mui/material';

import Label from 'src/components/Label';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { OrderModel } from 'src/server/models/order';
import { OrderStatus } from 'src/server/models/order_status';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useNavigate } from 'react-router';
import { useAction } from 'src/utils/hooks/action';
import { OffersActions } from '../module/actions';

interface CouponsTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}

const getStatusLabel = (status: OrderStatus): JSX.Element => {
  let color:
    | 'primary'
    | 'black'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info' = 'black';
  if (status == "Waiting For Payment") {
    color = 'warning';
  }
  if (status == "Pending") {
    color = 'secondary';
  }
  if (status == 'Processing') {
    color = 'info';
  }
  if (status == 'Completed') {
    color = 'success';
  }
  if (status == 'Refunded') {
    color = "error"
  }
  if (status == 'Failed' || status == 'Cancelled') {
    color = 'error';
  }

  return <div style={{ width: 150 }}><Label color={color}>{status}</Label></div>;
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const OffersTable: FC<CouponsTableProps> = ({ className }) => {
  const { offers, loading } = useSelector((state: RootState) => state.offers);
  const offersAction = useAction(OffersActions.offers.request);
  const navigate = useNavigate();

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    //setLimit(parseInt(event.target.value));
  };

  const theme = useTheme();

  return (
    <Card>
      {true && (
        <CardHeader
          style={{ background: '#11192A', color: 'white' }}
          title={"Offers " + '(' + offers.length + ')'}
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Start Date</TableCell>
              <TableCell align="left">End Date</TableCell>
              <TableCell align="left">Subtotal Minimum Amount</TableCell>
              <TableCell align="left">Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(offers ?? []).map((offer) => {
              return (
                <TableRow
                  hover
                  key={offer.id}
                  selected={false}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <a href={`/dashboard/offers/${offer?.id}`}>#{offer.id}</a>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {offer.name}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {offer.description}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {offer.price}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {offer.start_date}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {offer.end_date}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {offer.subtotal_minimum_amount}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {offer.is_active}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Tooltip title={"View Coupon #" + offer.id} arrow>
                      <Button variant="outlined"
                        onClick={() => {
                          navigate({
                            pathname: `/offers/${offer?.id}`
                          })
                        }}
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main,
                          width: 80
                        }}
                        color="inherit"
                        size="small"
                      >
                        View
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default OffersTable;
