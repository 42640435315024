import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  AttributesActions,
} from './actions';
import { AttributeModel } from '../../../server/models/attribute';
import { AttributesTypes } from './types';

const initialState: AttributesTypes = {
  attributes: [],
  loading: false,
  loadingCreateAttribute: false,
  createAttributeModal: false,
  createAttributeModalName: '',
  createAttributeModalNameAr: '',
  createAttributeErrorMessage: ''
};

export const attributesReducer = createReducer(initialState, handleAction => [
  handleAction(AttributesActions.attributes.request, (state) =>
    produce(state, draft => {
      draft.loading = true;
      draft.attributes = [];
    }),
  ),
  handleAction(AttributesActions.attributes.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.attributes = payload;
    }),
  ),
  handleAction(AttributesActions.attributes.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Create User
  handleAction(AttributesActions.create.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCreateAttribute = true;
    }),
  ),
  handleAction(AttributesActions.create.success, (state) =>
    produce(state, draft => {
      draft.loadingCreateAttribute = false;
      draft.createAttributeModal = false
    }),
  ),
  handleAction(AttributesActions.create.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createAttributeErrorMessage = payload
      draft.loadingCreateAttribute = false;
    }),
  ),

  // Create User Modal
  handleAction(AttributesActions.createAttributeModel, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createAttributeModal = payload;
      draft.createAttributeModalName = ''
    }),
  ),
  
  // Fields
  handleAction(AttributesActions.setCreateAttributeModelName, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createAttributeModalName = payload;
    }),
  ),

  handleAction(AttributesActions.setCreateAttributeModelNameAr, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createAttributeModalNameAr = payload;
    }),
  ),
  
  handleAction(AttributesActions.reset, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingCreateAttribute = false
      draft.createAttributeModal = false
      draft.createAttributeModalName = ''
      draft.createAttributeModalNameAr = ''
      draft.createAttributeErrorMessage = ''
    }),
  ),
]);
