import { Button, Divider } from '@mui/material';
import React from 'react';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useAction } from 'src/utils/hooks/action';
import { OfferActions } from '../module/actions';

function OfferHeader(props: { title: string, description: string }) {
  const { assignCategoryToOfferModal, assignProductToOfferModal } = useSelector((state: RootState) => state.offer);
  const setShowAssignProduct = useAction(OfferActions.showAssignProductModal);
  const setShowAssignCategory = useAction(OfferActions.showAssignCategoryModal);

  return (
    <div style={{ marginTop: 25, marginBottom: 20, marginLeft: 30, marginRight: 30 }}>
      <div style={{ display: 'flex', alignItems: 'cennter', justifyContent: 'space-between' }}>
        <div>
          <div style={{ fontSize: 25, fontWeight: 'bold' }}>
            {props.title}
          </div>
          {props.description.length > 0 && <div style={{ fontSize: 12 }}>
            {props.description}
          </div>}
        </div>

        <div style={{display: 'flex'}}>
        <Button
          style={{ height: 45, marginTop: 8, background:'#1F2737' }}
          onClick={() => {
            // Perform action
            setShowAssignProduct(true)
          }}
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          ASSIGN PRODUCT
        </Button>

        <div style={{width: 10}}/>

        <Button
          style={{ height: 45, marginTop: 8, background:'#1F2737' }}
          onClick={() => {
            // Perform action
            setShowAssignCategory(true)
          }}
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          ASSIGN CATEGORY
        </Button>
        </div>
      </div>
      <Divider style={{ marginBottom: 20, marginTop: 20 }} />
    </div>
  );
}

export default OfferHeader;
