import React from 'react';
import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { RootState } from '../../../store/rootState';
import { CouponsApis } from 'src/server/api/Coupons';
import { CategoriesActions } from './actions';


function* categories(action: ActionType<typeof CategoriesActions.categories.request>): SagaIterator {
  try {
    console.log('request categories saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AppApis.categories) as any;
    console.log('categories response: ');
    console.log(data);
    yield* put(CategoriesActions.categories.success(data.data)) as any;
  } catch (error) {
    yield* put(CategoriesActions.categories.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

function* create({ payload }: ActionType<typeof CategoriesActions.create.request>): SagaIterator {
  try {
    console.log('request create category saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AppApis.createCategories, payload) as any;
    console.log('create category response:');
    console.log(data);
    yield* put(CategoriesActions.create.success()) as any;

    const categoriesData = yield* call(AppApis.categories) as any;
    yield* put(CategoriesActions.categories.success(categoriesData.data.data)) as any;
  } catch (error) {
    console.log(error.response)
    yield* put(CategoriesActions.create.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

function* updateMetadata({ payload }: ActionType<typeof CategoriesActions.updateMetadata.request>): SagaIterator {
  try {
    console.log("Metadata request")
    console.log(payload)
    console.log('request update category metadata saga triggered')
    yield* put(AppActions.insureToken()) as any;

    const { selectedCategory } = yield* select((state: RootState) => state.categories) as any;
    const { data } = yield* call(AppApis.updateMetadata, {metadata: payload, id: selectedCategory.id}) as any;
    console.log('update category metadata response: ');
    console.log(data);
    yield* put(CategoriesActions.updateMetadata.success()) as any;

    const categoriesData = yield* call(AppApis.categories) as any;
    yield* put(CategoriesActions.categories.success(categoriesData.data.data)) as any;
  } catch (error) {
    console.log(error.response)
    yield* put(CategoriesActions.updateMetadata.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

export function* watchCategories(): SagaIterator {
  yield* takeLatest(CategoriesActions.categories.request, categories) as any;
  yield* takeLatest(CategoriesActions.create.request, create) as any;
  yield* takeLatest(CategoriesActions.updateMetadata.request, updateMetadata) as any;
}