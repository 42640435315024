import { createAction } from 'deox';
import { AttributeModel } from 'src/server/models/attribute';
import { CreateCategoryModel } from 'src/server/models/create_category';



export class AttributesActions {
  static attributes = {
    request: createAction('attributes/attributes_request'),
    success: createAction('attributes/attributes_success', resolve => (payload: AttributeModel[]) =>
      resolve(payload),
    ),
    fail: createAction('attributes/attributes_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static create = {
    request: createAction('categories/create_category_request', resolve => (payload: CreateCategoryModel) =>
      resolve(payload),
    ),
    success: createAction('categories/create_category_success'),
    fail: createAction('categories/create_category_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Create Attribute
  static createAttributeModel = createAction('categories/create_attribute_model', resolve => (payload: boolean) =>
    resolve(payload),
  );

  static setCreateAttributeModelName = createAction('categories/create_attributes_model_name', resolve => (payload: string) =>
    resolve(payload),
  );
  
  static setCreateAttributeModelNameAr = createAction('categories/create_attributes_model_name_ar', resolve => (payload: string) =>
    resolve(payload),
  );

  // Create Attribute Value
  static createAttributeValueModel = createAction('categories/create_attribute_model', resolve => (payload: boolean) =>
    resolve(payload),
  );

  static setCreateAttributeValueModelName = createAction('categories/create_attributes_model_name', resolve => (payload: string) =>
    resolve(payload),
  );
  
  static setCreateAttributeValueModelNameAr = createAction('categories/create_attributes_model_name_ar', resolve => (payload: string) =>
    resolve(payload),
  );

  static reset = createAction('categories/reset');
}