import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useAction } from 'src/utils/hooks/action';
import { ProductsActions } from '../module/actions';
import ProductsHeader from '../components/ProductsHeader';
import Products from '../components/Products';

function BestSellerProductsPage() {
  const { loading, error } = useSelector((state: RootState) => state.orders);
  const productsAction = useAction(ProductsActions.products.request);

  useEffect(() => {
    productsAction({ page: 0, min_price: 0, is_best_seller: 1 });
  }, [productsAction]);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error loading data</>;
  }

  return (
    <>
      <Helmet>
        <title>Products - Best Seller</title>
      </Helmet>
      <PageTitleWrapper>
        <ProductsHeader title={'Unavailable Products'} description="" />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Products />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default BestSellerProductsPage;
