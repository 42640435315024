import React from 'react';
import { Card } from '@mui/material';
import { CryptoOrder } from 'src/models/crypto_order';
import OrderProductsTable from './OfferCategoriesTable';
import { subDays } from 'date-fns';
import { OrderModel } from 'src/server/models/order';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { LocalDiningRounded } from '@mui/icons-material';
import SuspenseLoader from 'src/components/SuspenseLoader';
import ProductCategoriesTable from './OfferCategoriesTable';
import CouponCategoriesTable from './OfferCategoriesTable';
import OfferCategoriesTable from './OfferCategoriesTable';

function OfferCategories() {
  const { loading } = useSelector((state: RootState) => state.offer);

  if(loading) {
    return <div></div>
  }

  return (
    <Card>
      { !loading && <OfferCategoriesTable/>}
    </Card>
  );
}

export default OfferCategories;
