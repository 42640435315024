import { Helmet } from 'react-helmet-async';
import PageHeader from './components/OffersHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import React from 'react';
import Orders from './components/Offers';
import { useEffect, useState } from 'react';
import { RootState } from 'src/store/rootState';
import { useSelector } from 'react-redux';
import { useAction } from 'src/utils/hooks/action';
import { OrdersActions } from 'src/pages/orders/module/actions';
import { OffersActions } from './module/actions';
import ProductsHeader from './components/OffersHeader';
import Products from './components/Offers';
import CouponsHeader from './components/OffersHeader';
import Offers from './components/Offers';
import CreateCouponModal from './components/CreateOfferModal';
import OffersHeader from './components/OffersHeader';
import CreateOfferModal from './components/CreateOfferModal';

function OffersPage() {
  const { loading } = useSelector((state: RootState) => state.offers);
  const offersAction = useAction(OffersActions.offers.request);
  const resetAction = useAction(OffersActions.reset);

  useEffect(() => {
    resetAction();
    offersAction();
  }, [])

  if(loading) {
    <>Loading...</>
  }
  
  return (
    <>
      <Helmet>
        <title>Offers</title>
      </Helmet>
      <OffersHeader title={'Offers'} description="Create offer to apply on products." />
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Offers />
          </Grid>
        </Grid>
      </Container>
      <CreateOfferModal/>
      <Footer />
    </>
  );
}

export default OffersPage;
