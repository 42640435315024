import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { OrdersActions } from './actions';
import { AuthApis } from '../../../server/api/Auth';
import { Api } from '../../../server/api';
import { AppActions } from 'src/modules/app/actions';
import { OrdersApis } from 'src/server/api/Orders';

function* orders({payload} : ActionType<typeof OrdersActions.orders.request>): SagaIterator {
  try {
    console.log('orders saga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OrdersApis.orders, payload) as any;
    console.log('orders response:');
    console.log(data);
    yield* put(OrdersActions.orders.success((data as any).data)) as any;
    
  } catch (error) {
    console.log('orders error')
    console.log(error)
    try {
      const errorMessage = (error as any).response.data.message;
      console.log('error in login')
      console.log((error as any).response.data.message)
      yield* put(OrdersActions.orders.fail((error as any).response.data.message)) as any;
    } 
    catch {
      yield* put(OrdersActions.orders.fail('Unable to login, Please try again later')) as any;
    }
  }
}


export function* watchOrders(): SagaIterator {
  yield* takeLatest(OrdersActions.orders.request, orders) as any;
}
