import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  CouponActions,
} from './actions';
import { CouponTypes } from './types';
import { CategoryModel } from 'src/server/models/category';

const initialState: CouponTypes = {
  loading: false,
  products: [],
  categories: [],
  coupon: {
    id: '',
    code: '',
    type: '',
    amount: 0,
    date_created: '',
    description: '',
    date_expires: '',
    subtotal_minimum_amount: 0,
    reduction_maximum_amount: 0,
    allow_free_shipping: 0,
    quantity: 0,
    used_quantity: 0,
    max_products_usage: 0
  },
  assignCategoryToCouponModal: {
    error: '',
    loading: false,
    categories: [],
    selectedCategoryId: ''
  },
  assignProductToCouponModal: {
    error: '',
    loading: false,
    barcode: undefined
  },
  showAssignCategoryModal: false,
  showAssignProductModal: false
};

export const couponReducer = createReducer(initialState, handleAction => [
  // Coupon
  handleAction(CouponActions.coupon.request, (state, payload) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(CouponActions.coupon.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.categories = payload.categories;
      draft.coupon = payload.coupon;
      draft.products = payload.products
    }),
  ),
  handleAction(CouponActions.coupon.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Assign category to coupon
  handleAction(CouponActions.assignCategoryToCouponModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToCouponModal = payload;
    }),
  ),

  // Assign product to coupon
  handleAction(CouponActions.assignProductToCouponModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToCouponModal = payload;
    }),
  ),

  // Categories to assign
  handleAction(CouponActions.categoriesToAssign.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToCouponModal = {
        ...draft.assignCategoryToCouponModal, 
        loading: true
      }
    }),
  ),

  handleAction(CouponActions.categoriesToAssign.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToCouponModal = {
        ...draft.assignCategoryToCouponModal, 
        loading: false, 
        categories: payload,
        selectedCategoryId: (payload as CategoryModel[]).length > 0 ? payload[0] : ''
      }
    }),
  ),

  handleAction(CouponActions.categoriesToAssign.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToCouponModal = {...draft.assignCategoryToCouponModal, loading: false, categories: [], error: payload}
    }),
  ),

  // Assign Category
  handleAction(CouponActions.assignCategory.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToCouponModal = {...draft.assignCategoryToCouponModal, loading: true}
    }),
  ),

  handleAction(CouponActions.assignCategory.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToCouponModal = {
        ...draft.assignCategoryToCouponModal, 
        loading: false, 
      }
      draft.showAssignCategoryModal = false
    }),
  ),

  handleAction(CouponActions.assignCategory.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToCouponModal = {
        ...draft.assignCategoryToCouponModal, 
        error: payload,
        loading: false
      }
    }),
  ),

  // Assign Product
  handleAction(CouponActions.assignProduct.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToCouponModal = {
        ...draft.assignProductToCouponModal, 
        loading: true
      }
    }),
  ),

  handleAction(CouponActions.assignProduct.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToCouponModal = {
        ...draft.assignProductToCouponModal, 
        loading: false, 
      }
      draft.showAssignProductModal = false
    }),
  ),

  handleAction(CouponActions.assignProduct.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToCouponModal = {
        ...draft.assignProductToCouponModal, 
        error: payload,
        loading: false
      }
      draft.showAssignProductModal = true
    }),
  ),

  // Show assign product
  handleAction(CouponActions.showAssignProductModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showAssignProductModal = payload
    }),
  ),

  // Show assign category
  handleAction(CouponActions.showAssignCategoryModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showAssignCategoryModal = payload
    }),
  ),
]);