import React from 'react';
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  CardHeader
} from '@mui/material';

import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from 'src/components/Text';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { ProductModel } from 'src/server/models/product';

function OfferDetails() {
  const { offer } = useSelector((state: RootState) => state.offer);

  // const discount = (product: ProductModel) => {
  //   return Math.floor((100 - (+product.sale_price / +product.regular_price) * 100));
  // }

  if (!offer) {
    return <></>
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          {true && (
            <CardHeader
              style={{ background: '#11192A', color: 'white' }}
              // action={
              //   <Box width={150}>
              //     <FormControl fullWidth variant="outlined">
              //       <InputLabel>Status</InputLabel>
              //       <Select
              //         value={filters.status || 'all'}
              //         onChange={handleStatusChange}
              //         label="Status"
              //         autoWidth
              //       >
              //         {statusOptions.map((statusOption) => (
              //           <MenuItem key={statusOption.id} value={statusOption.id}>
              //             {statusOption.name}
              //           </MenuItem>
              //         ))}
              //       </Select>
              //     </FormControl>
              //   </Box>
              // }
              title={"Details"}
            />
          )}
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                {/* Id */}
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'left' }}>
                  <Box pr={3} pb={2}>
                    ID:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>#{offer.id}</b>
                  </Text>
                </Grid>

                {/* Availablility */}
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'left' }}>
                  <Box pr={3} pb={2}>
                    Name:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{offer.name}</b>
                  </Text>
                </Grid>

                {/* Image */}
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'left' }}>
                  <Box pr={3} pb={2}>
                    Description:
                  </Box>
                </Grid>
                <Grid direction={'row'} item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{offer.description}</b>
                  </Text>
                </Grid>

                {/* Sku */}
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'left' }}>
                  <Box pr={3} pb={2}>
                    Start Date:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{offer.start_date}</b>
                  </Text>
                </Grid>

                {/* Barcode */}
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'left' }}>
                  <Box pr={3} pb={2}>
                    End Date:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{offer.end_date}</b>
                  </Text>
                </Grid>

                {/* English Name */}
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'left' }}>
                  <Box pr={3} pb={2}>
                    Minimum Subtotal:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{offer.subtotal_minimum_amount}</b>
                  </Text>
                </Grid>

                {/* Regular Price */}
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'left' }}>
                  <Box pr={3} pb={2}>
                    Active:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{offer.is_active}</b>
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default OfferDetails;
