import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import axios from 'axios';
import { HomeApis } from '../../../server/api/Home';
import { OfferActions } from './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { OffersApis } from 'src/server/api/Offers';


function* offer({ payload }: ActionType<typeof OfferActions.offer.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { data } = yield* call(OffersApis.offer, payload) as any;
    console.log('offers response: ');
    console.log(data);
    yield* put(OfferActions.offer.success(data.data)) as any;
  } catch (error) {
    console.log(error.response);
    yield* put(OfferActions.offer.fail('Something went wrong while trying to load offers')) as any;
  }
}

function* categoriesToAssign(action: ActionType<typeof OfferActions.categoriesToAssign.request>): SagaIterator {
  try {
    console.log('request categories saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AppApis.categories) as any;
    console.log('categories response: ');
    console.log(data);
    yield* put(OfferActions.categoriesToAssign.success(data.data)) as any;
  } catch (error) {
    yield* put(OfferActions.categoriesToAssign.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

function* assignCategory({ payload }: ActionType<typeof OfferActions.assignCategory.request>): SagaIterator {
  try {
    console.log('request assign category saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OffersApis.assignCategory, payload) as any;
    console.log('assign category response: ');
    console.log(data);
    yield* put(OfferActions.assignCategory.success()) as any;

    const offerResponse = yield* call(OffersApis.offer, { id: payload.categoryId }) as any;
    yield* put(OfferActions.offer.success(offerResponse.data.data)) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      console.log(error.response.data['messsage']);
      yield* put(OfferActions.assignCategory.fail(error.response.data['messsage'])) as any;
    } else {
      yield* put(OfferActions.assignCategory.fail("Something went wrong while trying to assign category to offer, Please try again later.")) as any;
    }
  }
}

function* assignProduct({ payload }: ActionType<typeof OfferActions.assignProduct.request>): SagaIterator {
  try {
    console.log('request assign product saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OffersApis.assignProduct, payload) as any;
    console.log('assign product response: ');
    console.log(data);
    yield* put(OfferActions.assignProduct.success()) as any;

    const offerResponse = yield* call(OffersApis.offer, { id: payload.offerId }) as any;
    yield* put(OfferActions.offer.success(offerResponse.data.data)) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      console.log(error.response.data['messsage']);
      yield* put(OfferActions.assignProduct.fail(error.response.data['messsage'])) as any;
    } else {
      yield* put(OfferActions.assignProduct.fail("Something went wrong while trying to assign product to offer, Please try again later.")) as any;
    }
  }
}

export function* watchOffer(): SagaIterator {
  yield* takeLatest(OfferActions.offer.request, offer) as any;
  yield* takeLatest(OfferActions.categoriesToAssign.request, categoriesToAssign) as any;
  yield* takeLatest(OfferActions.assignCategory.request, assignCategory) as any;
  yield* takeLatest(OfferActions.assignProduct.request, assignProduct) as any;
}