import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { CategoryModel } from 'src/server/models/category';
import { CouponModel } from 'src/server/models/coupon';
import { CouponPayload } from 'src/server/payload/copoun';
import { UserModel } from 'src/server/models/user';
import { CreateUserPayload } from 'src/server/payload/create_user';


export class UsersActions {
  static users = {
    request: createAction('users/users_request'),
    success: createAction('users/users_success', resolve => (payload: UserModel[]) =>
      resolve(payload),
    ),
    fail: createAction('users/users_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static createUser = {
    request: createAction('users/create_user_request', resolve => (payload: CreateUserPayload) =>
        resolve(payload),
        ),
    success: createAction('users/create_user_success'),
    fail: createAction('users/create_user_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static createUserModel = createAction('users/create_user_model', resolve => (payload: boolean) =>
    resolve(payload),
  );

  static createUserUsername = createAction('users/create_user_model_username', resolve => (payload: string) =>
    resolve(payload),
  );

  static createUserPassword = createAction('users/create_user_model_password', resolve => (payload: string) =>
    resolve(payload),
  );
}