import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  OffersActions,
} from './actions';
import { OffersTypes } from './types';

const initialState: OffersTypes = {
  offers: [],
  loading: false,
  createOfferModal: {
    show: false,
    error: '',
    loading: false,
    price: '',
    name: '',
    description: '',
    start_date: undefined,
    end_date: undefined,
    image: '',
    is_active: false,
    subtotal_minimum_amount: ''
  }
};

export const offersReducer = createReducer(initialState, handleAction => [
  handleAction(OffersActions.reset, (state) =>
    produce(state, draft => {
      draft.createOfferModal = {
        ...draft.createOfferModal, 
        loading: false,
        show: false
      };
    }),
  ),
  handleAction(OffersActions.offers.request, (state) =>
    produce(state, draft => {
      draft.loading = true;
      draft.offers = [];
    }),
  ),
  handleAction(OffersActions.offers.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.offers = payload;
    }),
  ),
  handleAction(OffersActions.offers.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),
  handleAction(OffersActions.createOfferModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createOfferModal = payload;
    }),
  ),

  // Create
  handleAction(OffersActions.create.request, (state, { payload }) =>
    produce(state, draft => {
      draft.createOfferModal = {...draft.createOfferModal, loading: true};
    }),
  ),
  handleAction(OffersActions.create.success, (state) =>
    produce(state, draft => {
      draft.createOfferModal = {
        ...draft.createOfferModal, 
        loading: false,
        show: false
      };
    }),
  ),
  handleAction(OffersActions.create.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createOfferModal = {
        ...draft.createOfferModal, 
        error: payload,
        loading: false
      };
    }),
  ),
]);