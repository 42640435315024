import React from 'react'; // Added import for React
import { Card } from '@mui/material';
import ProductsTable from './ProductsTable';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';

function Products() {
  const { loading } = useSelector((state: RootState) => state.products);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      {!loading && <ProductsTable />}
    </Card>
  );
}

export default Products;