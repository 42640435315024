import { Card } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import OffersTable from './OffersTable';

function Offers() {
  const { loading } = useSelector((state: RootState) => state.coupons);

  if(loading) {
    return <div>Loading...</div>
  }

  return (
    <Card>
      { !loading && <OffersTable/>}
    </Card>
  );
}

export default Offers;
