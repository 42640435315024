import { FC } from 'react';
import React from 'react';
import {
  Divider,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  CardHeader
} from '@mui/material';

import Label from 'src/components/Label';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import { OrderModel } from 'src/server/models/order';
import { OrderStatus } from 'src/server/models/order_status';
import { useAction } from 'src/utils/hooks/action';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { OrdersActions } from 'src/pages/orders/module/actions';
import { UPLOADS_URL } from 'src/configs';

interface OfferCategoriesTableProps {
  className?: string;
}

const OfferCategoriesTable: FC<OfferCategoriesTableProps> = () => {
  const { categories } = useSelector((state: RootState) => state.offer);
  
  if(!categories) {
    return <>Loading...</>
  }

  return (
    <Card>
      {/* {true && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )} */}
      {true && (
        <CardHeader
        style={{background: '#11192A', color:'white'}}
          // action={
          //   <Box width={150}>
          //     <FormControl fullWidth variant="outlined">
          //       <InputLabel>Status</InputLabel>
          //       <Select
          //         value={filters.status || 'all'}
          //         onChange={handleStatusChange}
          //         label="Status"
          //         autoWidth
          //       >
          //         {statusOptions.map((statusOption) => (
          //           <MenuItem key={statusOption.id} value={statusOption.id}>
          //             {statusOption.name}
          //           </MenuItem>
          //         ))}
          //       </Select>
          //     </FormControl>
          //   </Box>
          // }
          title={"Categories (" +  categories.length + ")"}
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              {/* <TableCell align="left">Image</TableCell> */}
              <TableCell align="left">Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(categories ?? []).map((category) => {
              return (
                <TableRow
                  hover
                  key={category.id}
                  selected={false}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      #{category.id} &emsp;&emsp;&emsp;&emsp;&emsp;
                    </Typography>
                  </TableCell>
                  
                  {/* <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <div style={{border: '1px solid #f1f1f1f1', borderRadius: 4}}><img style={{width: 50, height: 50}} src={UPLOADS_URL + category.image}/></div>
                    </Typography>
                  </TableCell> */}

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {category.name_en}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default OfferCategoriesTable;
