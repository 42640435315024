import { all } from 'redux-saga/effects';
import { watchApp } from '../modules/app/sagas';
import { watchAuth } from '../pages/auth/module/sagas';
import { watchOrders } from 'src/pages/orders/module/sagas';
import { watchOrder } from 'src/pages/order/module/sagas';
import { watchProducts } from 'src/pages/products/module/sagas';
import { watchProduct } from 'src/pages/product/module/sagas';
import { watchCoupons } from 'src/pages/coupons/module/sagas';
import { watchCoupon } from 'src/pages/coupon/module/sagas';
import { watchSlider } from 'src/pages/sliders/module/sagas';
import { watchUsers } from 'src/pages/users/module/sagas';
import { watchCategories } from 'src/pages/categories/module/sagas';
import { watchBrands } from 'src/pages/brands/module/sagas';
import { watchAttributes } from 'src/pages/attributes/module/sagas';
import { watchOffers } from 'src/pages/offers/module/sagas';
import { watchOffer } from 'src/pages/offer/module/sagas';

export default function* rootSaga() {
  yield all([
    watchApp(),
    watchAuth(),
    watchOrders(),
    watchOrder(),
    watchProducts(),
    watchProduct(),
    watchCoupons(),
    watchOffers(),
    watchOffer(),
    watchCoupon(),
    watchSlider(),
    watchUsers(),
    watchCategories(),
    watchBrands(),
    watchAttributes()
  ]);
}
