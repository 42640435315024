import { CREATE_USER_URL, USERS_URL } from '../../configs';
import { CreateUserPayload } from '../payload/create_user';
import { Api } from './Api';

export class UsersApis {
  static users(): ReturnType<typeof Api.get> {
    return Api.get(USERS_URL);
  }

  static createUser(payload: CreateUserPayload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_USER_URL, payload);
  }
}