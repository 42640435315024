import { Api } from './Api';
import { getUrl } from '../../utils/helper';
import { ASSIGN_CATEGORY_TO_COUPON_URL, ASSIGN_PRODUCT_TO_COUPON_URL, COUPONS_URL, COUPON_URL, CREATE_COUPON_URL, PRODUCTS_URL, PRODUCT_URL } from '../../configs';
import { CouponPayload } from '../payload/copoun';
import { CreateCouponPayload } from '../payload/create_coupon';


export class CouponsApis {
  static coupons(): ReturnType<typeof Api.get> {
    return Api.get(COUPONS_URL);
  }

  static coupon(payload: CouponPayload): ReturnType<typeof Api.get> {
    return Api.get(COUPON_URL, payload);
  }

  static create(payload: CreateCouponPayload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_COUPON_URL, payload);
  }

  static assignCategory(payload: any): ReturnType<typeof Api.post> {
    return Api.post(ASSIGN_CATEGORY_TO_COUPON_URL, payload);
  }

  static assignProduct(payload: any): ReturnType<typeof Api.post> {
    return Api.post(ASSIGN_PRODUCT_TO_COUPON_URL, payload);
  }
}