import { LOGIN_URL, REGISTER_URL } from '../../configs';
import { LoginRequestPayload } from '../payload/login';
import { RegisterRequestPayload } from '../payload/register';
import { Api } from './Api';

export class AuthApis {
  static login(payload: LoginRequestPayload): ReturnType<typeof Api.post> {
    const data = new FormData(); // Changed 'var' to 'const'
    data.append("username", payload.username);
    data.append("password", payload.password);
    console.log(`data sent: ${JSON.stringify(payload)}`); // Ensures payload is properly serialized
    console.log(`username form: ${data.get('username')}`);
    console.log(`password form: ${data.get('password')}`);
    return Api.post(LOGIN_URL, data);
  }

  static register(payload: RegisterRequestPayload): ReturnType<typeof Api.post> {
    return Api.post(REGISTER_URL, payload);
  }
}
