import React from 'react'; // Added React import
import { Button, Divider } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

function ProductsHeader(props: {title: string, description: string}) {
  return (
    <div style={{ marginTop: 25, marginBottom: 20, marginLeft: 30, marginRight: 30 }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <div style={{ fontSize: 25, fontWeight: 'bold' }}>
            {props.title}
          </div>
          {props.description.length > 0 && <div style={{ fontSize: 12 }}>
            {props.description}
          </div>}
        </div>

        <Button
          style={{ height: 45, marginTop: 8, background:'#1F2737' }}
          onClick={() => { /* TODO: Implement create functionality */ }}
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          CREATE
        </Button>
        
      </div>
      <Divider style={{ marginBottom: 20, marginTop: 20 }} />
    </div>
  );
}

export default ProductsHeader;
