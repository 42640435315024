import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  UsersActions,
} from './actions';
import { UsersTypes } from './types';

const initialState: UsersTypes = {
  loading: false,
  users: [],
  createUserModal: false,
  loadingCreateUser: false,
  createUserErrorMessage: '',
  createUserUsername: '',
  createUserPassword: ''
};

export const usersReducer = createReducer(initialState, handleAction => [
  handleAction(UsersActions.users.request, (state, payload) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(UsersActions.users.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.users = payload;
    }),
  ),
  handleAction(UsersActions.users.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Create User
  handleAction(UsersActions.createUser.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCreateUser = true;
    }),
  ),
  handleAction(UsersActions.createUser.success, (state) =>
    produce(state, draft => {
      draft.loadingCreateUser = false;
      draft.createUserModal = false
    }),
  ),
  handleAction(UsersActions.createUser.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createUserErrorMessage = payload
      draft.loadingCreateUser = false;
    }),
  ),

  // Create User Modal
  handleAction(UsersActions.createUserModel, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createUserModal = payload;
      draft.createUserUsername = ''
      draft.createUserPassword = ''
    }),
  ),
  
  // Create User Modal
  handleAction(UsersActions.createUserUsername, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createUserUsername = payload;
    }),
  ),

  handleAction(UsersActions.createUserPassword, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createUserPassword = payload;
    }),
  ),
]);
