import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { CouponModel } from 'src/server/models/coupon';
import { CreateCouponModel } from 'src/server/models/create_coupon';
import CreateCouponModal from '../components/CreateOfferModal';
import { OfferModel } from 'src/server/models/offer';
import { CreateOfferModel } from 'src/server/models/create_offer';

export class OffersActions {
  static offers = {
    request: createAction('offers/offers_request'),
    success: createAction('offers/offers_success', resolve => (payload: OfferModel[]) =>
      resolve(payload),
    ),
    fail: createAction('offers/offers_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static create = {
    request: createAction('offers/coupon_create_request', resolve => (payload: CreateOfferModel) =>
      resolve(payload),
    ),
    success: createAction('offers/coupon_create_success'),
    fail: createAction('offers/coupon_create_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
  
  static createOfferModal = createAction('coupon/create_offer', resolve => (payload: CreateOfferModel) => resolve(payload),);
  
  static reset = createAction('coupon/reset');

}