import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  CouponsActions,
} from './actions';
import { CouponsTypes } from './types';

const initialState: CouponsTypes = {
  coupons: [],
  loading: false,
  createCouponModal: {
    show: false,
    loading: false,
    error: '',
    code: '',
    type: 'percentage_discount',
    amount: '',
    date_expires: new Date(),
    subtotal_minimum_amount: '',
    reduction_maximum_amount: '',
    allow_free_shipping: false,
    quantity: '',
    max_products_usage: '',
    products: [],
  }
};

export const couponsReducer = createReducer(initialState, handleAction => [
  handleAction(CouponsActions.reset, (state) =>
    produce(state, draft => {
      draft.createCouponModal = {
        ...draft.createCouponModal, 
        loading: false,
        show: false
      };
    }),
  ),
  handleAction(CouponsActions.coupons.request, (state) =>
    produce(state, draft => {
      draft.loading = true;
      draft.coupons = [];
    }),
  ),
  handleAction(CouponsActions.coupons.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.coupons = payload;
    }),
  ),
  handleAction(CouponsActions.coupons.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),
  handleAction(CouponsActions.createCouponModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCouponModal = payload;
    }),
  ),

  // Create
  handleAction(CouponsActions.create.request, (state, { payload }) =>
    produce(state, draft => {
      draft.createCouponModal = {...draft.createCouponModal, loading: true};
    }),
  ),
  handleAction(CouponsActions.create.success, (state) =>
    produce(state, draft => {
      draft.createCouponModal = {
        ...draft.createCouponModal, 
        loading: false,
        show: false
      };
    }),
  ),
  handleAction(CouponsActions.create.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCouponModal = {
        ...draft.createCouponModal, 
        error: payload,
        loading: false
      };
    }),
  ),
]);