import { createAction } from 'deox';
import { resolveConfig } from 'prettier';
import { LoginRequestPayload } from 'src/server/payload/login';

export class AuthActions {
  static login = {
    request: createAction('auth/login_request', resolve => (payload: LoginRequestPayload) =>
      resolve(payload),
    ),
    success: createAction('auth/login_success'),
    fail: createAction('auth/login_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}
