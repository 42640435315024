import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { RootState } from '../../../store/rootState';
import { CouponsApis } from 'src/server/api/Coupons';
import { CouponsActions } from './actions';
import { CreateCouponModel } from 'src/server/models/create_coupon';
import { CreateCouponPayload } from 'src/server/payload/create_coupon';
import moment from 'moment';


function* coupons(action: ActionType<typeof CouponsActions.coupons.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;

    const { data } = yield* call(CouponsApis.coupons) as any;
    console.log('coupons response: ');
    console.log(data);
    yield* put(CouponsActions.coupons.success(data.data)) as any;
  } catch (error) {
    console.log(error);
    yield* put(CouponsActions.coupons.fail('Something went wrong while trying to load coupons')) as any;
  }
}

function* create({ payload }: ActionType<typeof CouponsActions.create.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    const body: CreateCouponPayload = {
      coupon: {
        code: payload.code,
        type: payload.type ?? 'percentage_discount',
        amount: !payload.amount ? 0 : +payload.amount,
        date_expires: (moment(payload.date_expires)).format('YYYY-MM-DD HH:mm:ss'),
        subtotal_minimum_amount: !payload.subtotal_minimum_amount  ? 0 : +payload.subtotal_minimum_amount,
        reduction_maximum_amount: !payload.reduction_maximum_amount ? 0 : +payload.reduction_maximum_amount,
        allow_free_shipping: payload.allow_free_shipping ?? true,
        quantity: !payload.quantity ? 0 : +payload.quantity,
        max_products_usage: !payload.max_products_usage ? 0 : +payload.max_products_usage
      }
    }
    console.log('coupon payload')
    console.log(payload)
    const { data } = yield* call(CouponsApis.create, body) as any;
    console.log('coupons response: ');
    console.log(data);
    yield* put(CouponsActions.create.success()) as any;

    // Request coupons list
    const couponseResponse = yield* call(CouponsApis.coupons) as any;
    yield* put(CouponsActions.coupons.success(couponseResponse.data.data)) as any;
  } catch (error) {
      console.log(error);
      if (error.response.status == 400) {
        console.log(error.response.data.data['messsage']);
        yield* put(CouponsActions.create.fail(error.response.data.data['messsage'])) as any;
      } else {
        yield* put(CouponsActions.create.fail("Server Error, Something went wrong while trying to create coupon, Please try again later")) as any;
      }
    }
  }

export function* watchCoupons(): SagaIterator {
  yield* takeLatest(CouponsActions.coupons.request, coupons) as any;
  yield* takeLatest(CouponsActions.create.request, create) as any;
}