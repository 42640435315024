import { createReducer } from 'deox';
import { produce } from 'immer';
import { ProductsActions } from './actions';
import { ProductsTypes } from './types';

const initialState: ProductsTypes = {
  products: [],
  attributes: {},
  total_count: 0,
  page: 0,
  loading: false,
  loadingProducts: false,
  view: 'grid',
  payload: {
    id: '',
    name: '',
    min_price: 0,
    max_price: '',
    attrs: [],
    page: 0
  },
  metadataProductModal: false,
  loadingMetadataProductModal: false,
  metadataProductErrorMessage: '',
  metadata: {
    name: '',
    image: '',
    url: '',
    title: '',
    canonical: '',
    keywords: '',
    description: '',
    date: '',
    sitecode: '',
    twitter: {
      card: '',
      site: '',
      creator: '',
      url: '',
      title: '',
      description: '',
      image: ''
    },
    og: {
      url: '',
      image: '',
      type: '',
      site_name: '',
      locale: '',
      title: '',
      description: '',
      country_name: ''
    }
  },
  selectedProduct: {
    id: '',
    sku: '',
    name: '',
    name_en: '',
    image: '',
    regular_price: '',
    sale_price: '',
    description: '',
    description_en: '',
    stock_status: '',
    stock: undefined,
    is_flash_sell: '',
    product_attributes: '',
    product_attributes_en: '',
    categories: [],
    similar: [],
    images: [],
    attributes: [],
    order_quantity: undefined,
    is_available: undefined,
    brand_image: '',
    barcode: '',
    metadata: ''
  }
};

export const productsReducer = createReducer(initialState, handleAction => [
  handleAction(ProductsActions.products.request, (state, { payload }) =>
    produce(state, draft => {
      console.log('requesting sliders');
      draft.payload = payload;
      draft.page = payload.page;
      draft.loadingProducts = true;
    }),
  ),
  handleAction(ProductsActions.products.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingProducts = false;
      // Products
      draft.products = payload.products;
      // Total count
      draft.total_count = +payload.total_count;
      // Attributes
      try {
        const result = {};
        payload.attributes.forEach(attribute => {
          result[attribute.attr_name_en] = [...(result[attribute.attr_name_en] ?? []), attribute];
        });
        draft.attributes = result;
        console.log('#attributes');
        console.log(result);
      } catch (exception) {
        console.log(exception);
      }
    }),
  ),
  handleAction(ProductsActions.products.fail, (state) =>
    produce(state, draft => {
      draft.loadingProducts = false;
    }),
  ),
  // Update metadata
  handleAction(ProductsActions.updateMetadata.request, (state) =>
    produce(state, draft => {
      draft.loadingMetadataProductModal = true;
    }),
  ),
  handleAction(ProductsActions.updateMetadata.success, (state) =>
    produce(state, draft => {
      draft.loadingMetadataProductModal = false;
      draft.metadataProductModal = false;
    }),
  ),
  handleAction(ProductsActions.updateMetadata.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.metadataProductErrorMessage = payload;
      draft.loadingMetadataProductModal = false;
    }),
  ),
  handleAction(ProductsActions.setSelectedProduct, (state, { payload }) =>
    produce(state, draft => {
      draft.selectedProduct = payload;
    }),
  ),
  handleAction(ProductsActions.setMetadata, (state, { payload }: any) =>
    produce(state, draft => {
      draft.metadata = payload;
    }),
  ),
  handleAction(ProductsActions.setProductMetadataModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.metadataProductModal = payload;
    }),
  ),
  handleAction(ProductsActions.openProductMetadataModal, (state) =>
    produce(state, draft => {
      draft.metadataProductModal = true;
    }),
  ),
  handleAction(ProductsActions.closeProductMetadataModal, (state) =>
    produce(state, draft => {
      draft.metadataProductModal = false;
    }),
  ),
]);
