import {
  Grid,
  CircularProgress,
  Alert,
  CardHeader,
  Divider,
  Box,
  TextField,
  MenuItem,
  TextFieldProps,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FilledInput
} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useAction } from 'src/utils/hooks/action';
import { CouponsActions } from 'src/pages/coupons/module/actions';
import { ReactElement, JSXElementConstructor, useState, useEffect } from 'react';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { OfferActions } from '../module/actions';
import { AssignProductToCouponPayload } from 'src/server/payload/assign_product_to_coupon';
import { AssignProductToOfferPayload } from 'src/server/payload/assign_product_to_offer';

function AssignProductModal() {
  const { assignProductToOfferModal, offer, showAssignProductModal } = useSelector((state: RootState) => state.offer);
  const setAssignProductToOfferModal = useAction(OfferActions.assignProductToOfferModal);
  const assignProduct = useAction(OfferActions.assignProduct.request);
  const setShowAssignProduct = useAction(OfferActions.showAssignProductModal);

  const handleClose = () => {
    if (assignProductToOfferModal.loading) {
      return;
    }
    setShowAssignProduct(false)
  }

  if(!assignProductToOfferModal) {
    return <></>
  }

  return (
    <Dialog onClose={handleClose} open={showAssignProductModal ?? false}>
      {<List sx={{ pt: 0 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          {
          assignProductToOfferModal.loading && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: 20}}>
            <CircularProgress />
            </div>
            }
          { !assignProductToOfferModal.loading && <Grid item xs={12}>
            <div>
              <CardHeader title="Assign Product To The Coupon" />
              <Divider />
              <div style={{ marginTop: 8, marginBottom: 8, marginRight: 18 }}>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off"
                > 
                
                <TextField
                    required
                    style={{ width: '100%' }}
                    id="filled-required"
                    label="Product Barcode (AS ODOO)"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    variant="filled"
                    disabled={assignProductToOfferModal.loading}
                    value={assignProductToOfferModal.barcode}
                    onChange={(event) => {
                      setAssignProductToOfferModal({ ...assignProductToOfferModal, barcode: event.target.value });
                    }}
                  />

                </Box>
              </div>
              <Divider />
              {(assignProductToOfferModal.error ?? '').length > 0 && <Alert style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, marginTop: 14 }} severity="error">{assignProductToOfferModal.error}</Alert>}
              { <div style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
                {!assignProductToOfferModal.loading && <Button
                  color='error'
                  variant="outlined"
                  onClick={() => {
                    const payload = {
                      offerId: offer.id, 
                      barcode: assignProductToOfferModal.barcode
                    } as AssignProductToOfferPayload
                    console.log(
                      payload
                    )
                    assignProduct(payload)
                  }}
                >Submit
                </Button>
                }
                {assignProductToOfferModal.loading && <CircularProgress />}
              </div>}
            </div>
          </Grid>}
        </Grid>
      </List>
      }
    </Dialog>
  );
}

export default AssignProductModal;