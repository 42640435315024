import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  OfferActions,
} from './actions';
import { CategoryModel } from 'src/server/models/category';
import { OfferTypes } from './types';

const initialState: OfferTypes = {
  loading: false,
  products: [],
  categories: [],
  assignCategoryToOfferModal: {
    error: '',
    loading: false,
    categories: [],
    selectedCategoryId: ''
  },
  assignProductToOfferModal: {
    error: '',
    loading: false,
    barcode: undefined
  },
  showAssignCategoryModal: false,
  showAssignProductModal: false,
  offer: {
    id: '',
    name: '',
    description: '',
    image: '',
    price: 0,
    start_date: '',
    end_date: '',
    is_active: false,
    subtotal_minimum_amount: 0
  }
};

export const offerReducer = createReducer(initialState, handleAction => [
  // Offer
  handleAction(OfferActions.offer.request, (state, payload) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(OfferActions.offer.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.categories = payload.categories;
      draft.offer = payload.offer;
      draft.products = payload.products
    }),
  ),
  handleAction(OfferActions.offer.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Assign category to offer
  handleAction(OfferActions.assignCategoryToOfferModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToOfferModal = payload;
    }),
  ),

  // Assign product to offer
  handleAction(OfferActions.assignProductToOfferModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToOfferModal = payload;
    }),
  ),

  // Categories to assign
  handleAction(OfferActions.categoriesToAssign.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToOfferModal = {
        ...draft.assignCategoryToOfferModal, 
        loading: true
      }
    }),
  ),

  handleAction(OfferActions.categoriesToAssign.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToOfferModal = {
        ...draft.assignCategoryToOfferModal, 
        loading: false, 
        categories: payload,
        selectedCategoryId: (payload as CategoryModel[]).length > 0 ? payload[0] : ''
      }
    }),
  ),

  handleAction(OfferActions.categoriesToAssign.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToOfferModal = {...draft.assignCategoryToOfferModal, loading: false, categories: [], error: payload}
    }),
  ),

  // Assign Category
  handleAction(OfferActions.assignCategory.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToOfferModal = {...draft.assignCategoryToOfferModal, loading: true}
    }),
  ),

  handleAction(OfferActions.assignCategory.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToOfferModal = {
        ...draft.assignCategoryToOfferModal, 
        loading: false, 
      }
      draft.showAssignCategoryModal = false
    }),
  ),

  handleAction(OfferActions.assignCategory.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToOfferModal = {
        ...draft.assignCategoryToOfferModal, 
        error: payload,
        loading: false
      }
    }),
  ),

  // Assign Product
  handleAction(OfferActions.assignProduct.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToOfferModal = {
        ...draft.assignProductToOfferModal, 
        loading: true
      }
    }),
  ),

  handleAction(OfferActions.assignProduct.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToOfferModal = {
        ...draft.assignProductToOfferModal, 
        loading: false, 
      }
      draft.showAssignProductModal = false
    }),
  ),

  handleAction(OfferActions.assignProduct.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignProductToOfferModal = {
        ...draft.assignProductToOfferModal, 
        error: payload,
        loading: false
      }
      draft.showAssignProductModal = true
    }),
  ),

  // Show assign product
  handleAction(OfferActions.showAssignProductModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showAssignProductModal = payload
    }),
  ),

  // Show assign category
  handleAction(OfferActions.showAssignCategoryModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showAssignCategoryModal = payload
    }),
  ),
]);