import React, { FC } from 'react'; // Added React import
import { useTheme } from '@mui/material/styles';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  CardHeader,
  TablePagination,
  IconButton,
  Typography
} from '@mui/material';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useNavigate } from 'react-router';
import { ProductsActions } from '../module/actions';
import { useAction } from 'src/utils/hooks/action';
import { Link } from 'react-router-dom';
import MetaIcon from '@mui/icons-material/DataArray';

interface ProductsTableProps {
  className?: string;
}

const ProductsTable: FC<ProductsTableProps> = () => {  // Fixed the empty object pattern issue here
  const { products, page, total_count, payload } = useSelector((state: RootState) => state.products);
  const productsAction = useAction(ProductsActions.products.request);
  const navigate = useNavigate();
  const setProductMetadataModal = useAction(ProductsActions.setProductMetadataModal);
  const setSelectedProduct = useAction(ProductsActions.setSelectedProduct);

  const handlePageChange = (event: any, page: number): void => {
    productsAction({ ...payload, page: page });
  };

  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        style={{ background: '#11192A', color: 'white' }}
        title={"Products " + '(' + total_count + ')'}
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Availability</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">SKU</TableCell>
              <TableCell align="left">Barcode</TableCell>
              <TableCell align="left">Arabic Name</TableCell>
              <TableCell align="left">English Name </TableCell>
              <TableCell align="left">Regular Price</TableCell>
              <TableCell align="left">Sale Price</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(products ?? []).map((product) => {
              return (
                <TableRow hover key={product.id} selected={false}>
                  <TableCell>
                    <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                      <a href={`#/product/${product?.id}`}>#{product.id}</a>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Label color={product.is_available == 1 ? 'success' : 'error'}>
                      {product.is_available == 1 ? "Available" : "Unavailable"}
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                      <div style={{ border: '1px solid #f1f1f1f1', borderRadius: 4 }}>
                        <img style={{ width: 50, height: 50 }} src={product.image} />
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                      {product.sku}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {product.barcode ?? '-'}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                      {product.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {product.name_en}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                      AED {product.regular_price}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                      AED {product.sale_price}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                      {(product.description === '0' ? '-' : product.description) ?? '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title={"View Product #" + product.id} arrow>
                      <Button variant="outlined"
                        onClick={() => {
                          navigate({ pathname: `/product/${product?.id}` });
                        }}
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main,
                          width: 80
                        }}
                        color="inherit"
                        size="small"
                      >
                        <Link to={`/product/${product?.id}`}>
                          View
                        </Link>
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title="Metadata" arrow>
                      <IconButton
                        onClick={() => {
                          setSelectedProduct(product);
                          setProductMetadataModal(true);
                        }}
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <MetaIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={total_count}
          onPageChange={handlePageChange}
          page={page}
          rowsPerPage={40}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default ProductsTable;
