import React from 'react';
import { FC, ChangeEvent, useState } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader
} from '@mui/material';

import Label from 'src/components/Label';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import { OrderModel } from 'src/server/models/order';
import { OrderStatus } from 'src/server/models/order_status';
import { useAction } from 'src/utils/hooks/action';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { OrdersActions } from 'src/pages/orders/module/actions';

interface CouponProductsTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}


const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const CouponProductsTable: FC<CouponProductsTableProps> = () => {
  const { products, loading } = useSelector((state: RootState) => state.coupon);

  const theme = useTheme();

  if(!products) {
    return <></>
  }

  return (
    <Card>
      {true && (
        <CardHeader
        style={{background: '#11192A', color:'white'}}
          // action={
          //   <Box width={150}>
          //     <FormControl fullWidth variant="outlined">
          //       <InputLabel>Status</InputLabel>
          //       <Select
          //         value={filters.status || 'all'}
          //         onChange={handleStatusChange}
          //         label="Status"
          //         autoWidth
          //       >
          //         {statusOptions.map((statusOption) => (
          //           <MenuItem key={statusOption.id} value={statusOption.id}>
          //             {statusOption.name}
          //           </MenuItem>
          //         ))}
          //       </Select>
          //     </FormControl>
          //   </Box>
          // }
          title={"Products (" +  products.length + ")"}
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">SKU</TableCell>
              <TableCell align="left">Barcode</TableCell>
              <TableCell align="left">Arabic Name</TableCell>
              <TableCell align="left">English Name </TableCell>
              <TableCell align="left">Regular Price</TableCell>
              <TableCell align="left">Sale Price</TableCell>
              <TableCell align="left">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(products ?? []).map((product) => {
              return (
                <TableRow
                  hover
                  key={product.id}
                  selected={false}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <a href={'/products/' + product.id}>#{product.id}</a> &emsp;&emsp;&emsp;&emsp;&emsp;
                    </Typography>
                  </TableCell>
                  
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <div style={{border: '1px solid #f1f1f1f1', borderRadius: 4}}><img style={{width: 50, height: 50}} src={product.image}/></div>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {product.sku}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {product.barcode}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {product.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {product.name_en}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      AED {product.regular_price}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      AED {product.sale_price}
                    </Typography>
                  </TableCell>
                  
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {product.description}
                    </Typography>
                  </TableCell>


                  {/* <TableCell align="left">
                    <Tooltip title="Edit product" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <EditTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete product" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CouponProductsTable;
