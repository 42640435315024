import { Api } from './Api';
import { CATEGORIES_URL, CREATE_CATEGORY_URL, UPDATE_CATEGORY_METADATA_URL } from '../../configs';

export class AppApis {
  static categories(): ReturnType<typeof Api.get> {
    return Api.get(CATEGORIES_URL);
  }
  
  static createCategories(payload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_CATEGORY_URL, payload);
  }

  static updateMetadata(payload): ReturnType<typeof Api.post> {
    return Api.post(UPDATE_CATEGORY_METADATA_URL, payload);
  }
}
