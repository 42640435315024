import { createAction } from 'deox';
import { AddressModel } from 'src/server/models/address';
import { OrderModel } from 'src/server/models/order';
import { ProductModel } from 'src/server/models/product';
import { AcceptOrderPayload } from 'src/server/payload/accept_order';
import { CompleteOrderPayload } from 'src/server/payload/complete_order';
import { RejectOrderPayload } from 'src/server/payload/reject_order';

export class OrderActions {
  static order = {
    request: createAction('order/order_request', resolve => (id: string) =>
      resolve(id),
    ),
    success: createAction('order/order_success', resolve => (payload: {order: OrderModel, products: ProductModel[], billingAddress: AddressModel, shippingAddress: AddressModel}) =>
      resolve(payload),
    ),
    fail: createAction('order/order_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static accept = {
    request: createAction('order/accept_order_request', resolve => (id: AcceptOrderPayload) =>
      resolve(id),
    ),
    success: createAction('order/accept_order_success'),
    fail: createAction('order/accept_order_failure', resolve => (error: string) =>
      resolve(error),
    ),
  };

  static reject = {
    request: createAction('order/reject_order_request', resolve => (id: RejectOrderPayload) =>
      resolve(id),
    ),
    success: createAction('order/reject_order_success'),
    fail: createAction('order/reject_order_failure', resolve => (error: string) =>
      resolve(error),
    ),
  };

  static complete = {
    request: createAction('order/complete_order_request', resolve => (id: CompleteOrderPayload) =>
      resolve(id),
    ),
    success: createAction('order/complete_order_success'),
    fail: createAction('order/complete_order_failure', resolve => (error: string) =>
      resolve(error),
    ),
  };

  static redirect = createAction('order/redirect', resolve => (payload: boolean) => resolve(payload));

  static acceptOrderModal = createAction('order/accept_order_modal', resolve => (payload: boolean) => resolve(payload));
  
  static rejectOrderModal = createAction('order/reject_order_modal', resolve => (payload: boolean) => resolve(payload));

  static completeOrderModal = createAction('order/complete_order_modal', resolve => (payload: boolean) => resolve(payload));

  static reset = createAction('order/reset');

  static setAccepted = createAction('order/order_accepted', resolve => (payload: boolean) => resolve(payload));

  static setRejected = createAction('order/order_rejected', resolve => (payload: boolean) => resolve(payload));

  static setCompleted = createAction('order/order_completed', resolve => (payload: boolean) => resolve(payload));
}
