import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  ProductActions,
} from './actions';
import { ProductTypes } from './types';
import { AttributeModel } from '../../../server/models/attribute';
import { CategoryModel } from 'src/server/models/category';

const initialState: ProductTypes = {
  loading: false,
  product: undefined,

  showDeleteImageModal: false,
  showUploadImageModal: false,

  showUpdateDefaultImageModal: false,
  uploadImageModalLoading: false,
  uploadImageModalError: '',
  uploadImageModalBase64: '',
  deleteImageModalLoading: false,
  deleteImageModalError: '',
  deleteImageModalId: '',
  categories: [],
  showAssignCategoryModal: false,
  assignCategoryToProductModal: {
    error: '',
    loading: false,
    categories: [],
    selectedCategoryId: ''
  },
  updateProductDetails: {
    id: '',
    sku: '',
    name: '',
    name_en: '',
    image: '',
    regular_price: '',
    sale_price: '',
    description: '',
    description_en: '',
    stock_status: '',
    stock: undefined,
    is_flash_sell: '',
    product_attributes: '',
    product_attributes_en: '',
    categories: [],
    similar: [],
    images: [],
    attributes: [],
    order_quantity: undefined,
    is_available: undefined,
    brand_image: '',
    barcode: '',
    metadata: ''
  },
  showUpdateDetailsModal: false,
  updateDetailsModalLoading: false,
  updateDetailsModalError: ''
};

export const productReducer = createReducer(initialState, handleAction => [
  handleAction(ProductActions.product.request, (state, payload) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(ProductActions.product.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      // Products
      draft.product = payload.product;
      draft.updateProductDetails = payload.product
    }),
  ),
  handleAction(ProductActions.product.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  handleAction(ProductActions.categories.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      // Products
      draft.categories = payload;
    }),
  ),

  // Update product details request
  handleAction(ProductActions.updateDetails.request, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      // Products
      draft.updateDetailsModalLoading = true
      draft.updateDetailsModalError = ''
    }),
  ),
  handleAction(ProductActions.updateDetails.success, (state) =>
    produce(state, draft => {
      draft.loading = false;
      // Products
      draft.updateDetailsModalLoading = false
      draft.showUpdateDetailsModal = false
    }),
  ),
  handleAction(ProductActions.updateDetails.fail, (state, { payload } : any) =>
    produce(state, draft => {
      draft.loading = false;
      // Products
      draft.updateDetailsModalLoading = false
      draft.updateDetailsModalError = payload
    }),
  ),

  handleAction(ProductActions.updateDetails.set, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      // Products
      draft.updateProductDetails = payload
    }),
  ),

  // Upload Image Request
  handleAction(ProductActions.uploadImage.request, (state, payload) =>
    produce(state, draft => {
      draft.uploadImageModalLoading = true;
    }),
  ),
  handleAction(ProductActions.uploadImage.success, (state) =>
    produce(state, draft => {
      draft.uploadImageModalLoading = false;
      draft.showUploadImageModal = false
    }),
  ),
  handleAction(ProductActions.uploadImage.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.uploadImageModalLoading = false;
    }),
  ),

  // Delete Image Request
  handleAction(ProductActions.deleteImage.request, (state, payload) =>
    produce(state, draft => {
      draft.deleteImageModalLoading = true;
    }),
  ),
  handleAction(ProductActions.deleteImage.success, (state) =>
    produce(state, draft => {
      draft.deleteImageModalLoading = false;
      draft.showDeleteImageModal = false
    }),
  ),
  handleAction(ProductActions.deleteImage.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.deleteImageModalLoading = false;
    }),
  ),

  // Delete modal
  handleAction(ProductActions.setDeleteImageModalId, (state, { payload }: any) =>
    produce(state, draft => {
      draft.deleteImageModalId = payload
    }),
  ),

  // Modals Visibility
  handleAction(ProductActions.showUploadImageModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showUploadImageModal = payload;
    }),
  ),
  handleAction(ProductActions.showUpdateDefaultImageModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showUpdateDefaultImageModal = payload;
    }),
  ),
  handleAction(ProductActions.showDeleteImageModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showDeleteImageModal = payload;
      draft.deleteImageModalLoading = false
    }),
  ),

  // Modals States
  handleAction(ProductActions.setUploadImageModalImage, (state, { payload }: any) =>
    produce(state, draft => {
      draft.uploadImageModalBase64 = payload
    }),
  ),

  // Assign Category To Product
  handleAction(ProductActions.assignCategoryToProductModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToProductModal = payload;
      draft.assignCategoryToProductModal = {
        ...draft.assignCategoryToProductModal, 
        error: ''
      }
    }),
  ),
  // Categories to assign
  handleAction(ProductActions.categoriesToAssign.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToProductModal = {
        ...draft.assignCategoryToProductModal, 
        loading: true
      }
    }),
  ),

  handleAction(ProductActions.categoriesToAssign.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToProductModal = {
        ...draft.assignCategoryToProductModal, 
        loading: false, 
        categories: payload,
        selectedCategoryId: (payload as CategoryModel[]).length > 0 ? payload[0] : ''
      }
    }),
  ),

  handleAction(ProductActions.categoriesToAssign.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToProductModal = {...draft.assignCategoryToProductModal, loading: false, categories: [], error: payload}
    }),
  ),

  // Assign Category
  handleAction(ProductActions.assignCategory.request, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToProductModal = {...draft.assignCategoryToProductModal, loading: true}
    }),
  ),

  handleAction(ProductActions.assignCategory.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToProductModal = {
        ...draft.assignCategoryToProductModal, 
        loading: false, 
      }
      draft.showAssignCategoryModal = false
    }),
  ),

  handleAction(ProductActions.assignCategory.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.assignCategoryToProductModal = {
        ...draft.assignCategoryToProductModal, 
        error: payload,
        loading: false
      }
    }),
  ),

  handleAction(ProductActions.showAssignCategoryModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.showAssignCategoryModal = payload
    }),
  ),
  handleAction(ProductActions.showUpdateDetailsModal, (state, { payload }) =>
    produce(state, draft => {
      draft.updateDetailsModalLoading = false
      draft.showUpdateDetailsModal = payload;
    }),
  ),
  handleAction(ProductActions.reset, (state) =>
    produce(state, draft => {
      draft.showUpdateDetailsModal = false;
      draft.showAssignCategoryModal = false;
      draft.showDeleteImageModal = false;
      draft.showUpdateDefaultImageModal = false;
      draft.showUploadImageModal = false;
      draft.deleteImageModalLoading = false;
      draft.uploadImageModalLoading = false;
      draft.updateDetailsModalLoading = false
    }),
  ),
]);
