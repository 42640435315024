import React from 'react';
import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { RootState } from '../../../store/rootState';
import { CouponsApis } from 'src/server/api/Coupons';
import { BrandsActions } from './actions';
import { BrandsApis } from 'src/server/api/Brands';


function* brands(action: ActionType<typeof BrandsActions.brands.request>): SagaIterator {
  try {
    console.log('request categories saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(BrandsApis.brands) as any;
      console.log('categories response: ');
      console.log(data);
      yield* put(BrandsActions.brands.success(data.data)) as any;
  } catch (error) {
    yield* put(BrandsActions.brands.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

function* create({ payload }: ActionType<typeof BrandsActions.create.request>): SagaIterator {
  try {
    console.log('request create brand saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(BrandsApis.createBrand, payload) as any;
      console.log('create brand response: ');
      console.log(data);
      yield* put(BrandsActions.create.success()) as any;

      const brandsData = yield* call(BrandsApis.brands) as any;
      yield* put(BrandsActions.brands.success(brandsData.data.data)) as any;
  } catch (error) {
    console.log(error.response)
    yield* put(BrandsActions.create.fail('Something went wrong while trying to fetch brands, Please try again later.')) as any;
  }
}

export function* watchBrands(): SagaIterator {
  yield* takeLatest(BrandsActions.brands.request, brands) as any;
  yield* takeLatest(BrandsActions.create.request, create) as any;
}