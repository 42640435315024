import {
  Grid,
  CircularProgress,
  Alert,
  CardHeader,
  Divider,
  Box,
  TextField,
  MenuItem,
  TextFieldProps,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FilledInput
} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useAction } from 'src/utils/hooks/action';
import { ReactElement, JSXElementConstructor, useState } from 'react';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { OffersActions } from '../module/actions';

function CreateOfferModal() {
  const { createOfferModal } = useSelector((state: RootState) => state.offers);
  const setCreateOfferModal = useAction(OffersActions.createOfferModal);
  const createOffer = useAction(OffersActions.create.request);

  const handleClose = () => {
    if (createOfferModal.loading) {
      return;
    }
    setCreateOfferModal({ ...createOfferModal, show: false });
  }

  if (!createOfferModal) {
    return <></>
  }

  return (
    <Dialog onClose={handleClose} open={createOfferModal.show ?? false}>
      {<List sx={{ pt: 0 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <div>
              <CardHeader title="Create Offer" />
              <Divider />
              <div style={{ marginTop: 8, marginBottom: 8, marginRight: 18 }}>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    required
                    style={{ width: '100%' }}
                    id="filled-required"
                    label="Name"
                    variant="filled"
                    disabled={createOfferModal.loading}
                    value={createOfferModal.name}
                    onChange={(event) => {
                      setCreateOfferModal({ ...createOfferModal, name: event.target.value })
                    }}
                  />

                  <TextField
                    required
                    style={{ width: '100%' }}
                    id="filled-required"
                    label="Description"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    variant="filled"
                    disabled={createOfferModal.loading}
                    value={createOfferModal.description}
                    onChange={(event) => {
                      setCreateOfferModal({ ...createOfferModal, description: event.target.value })
                    }}
                  />
                  
                  <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                    <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                    <FilledInput
                      id="outlined-adornment-amount"
                      required
                      style={{ width: '100%' }}
                      startAdornment={<InputAdornment position="start">{'AED'}</InputAdornment>}
                      disabled={createOfferModal.loading}
                      value={createOfferModal.price}
                      onChange={(event) => {
                        setCreateOfferModal({ ...createOfferModal, price: event.target.value })
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                    <InputLabel htmlFor="outlined-adornment-amount">Subtotal Minimum Amount</InputLabel>
                    <FilledInput
                      id="outlined-adornment-amount"
                      required
                      style={{ width: '100%' }}
                      startAdornment={<InputAdornment position="start">{'AED'}</InputAdornment>}
                      disabled={createOfferModal.loading}
                      value={createOfferModal.subtotal_minimum_amount}
                      onChange={(event) => {
                        setCreateOfferModal({ ...createOfferModal, subtotal_minimum_amount: event.target.value })
                      }}
                    />
                  </FormControl>

                  <div style={{ width: '100%', margin: 10 }}>
                    <div style={{ background: '#ededed', borderBottom: '0.5px solid #000', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
                      <div style={{ color: '#636f85' }}>Start date</div>
                      <DatePicker selected={createOfferModal.start_date} onChange={(date) => setCreateOfferModal({ ...createOfferModal, start_date: date })} />
                    </div>
                  </div>

                  <div style={{ width: '100%', margin: 10 }}>
                    <div style={{ background: '#ededed', borderBottom: '0.5px solid #000', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
                      <div style={{ color: '#636f85' }}>End date</div>
                      <DatePicker selected={createOfferModal.end_date} onChange={(date) => setCreateOfferModal({ ...createOfferModal, end_date: date })} />
                    </div>
                  </div>
                  
                </Box>
              </div>
              <Divider />
              {(createOfferModal.error ?? '').length > 0 && <Alert style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, marginTop: 14 }} severity="error">{createOfferModal.error}</Alert>}
              <div style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
                {!createOfferModal.loading && <Button
                  color='error'
                  variant="outlined"
                  onClick={() => {
                    createOffer(createOfferModal)
                  }}
                >Submit
                </Button>
                }
                {createOfferModal.loading && <CircularProgress />}
              </div>
            </div>
          </Grid>
        </Grid>
      </List>
      }
    </Dialog>
  );
}

export default CreateOfferModal;