import React from 'react';
import { Card } from '@mui/material';
import { CryptoOrder } from 'src/models/crypto_order';
import OrderProductsTable from './CouponProductsTable';
import { subDays } from 'date-fns';
import { OrderModel } from 'src/server/models/order';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { LocalDiningRounded } from '@mui/icons-material';
import SuspenseLoader from 'src/components/SuspenseLoader';
import CouponProductsTable from './CouponProductsTable';

function OfferProducts() {
  const { loading } = useSelector((state: RootState) => state.offer);

  if(loading) {
    return <div>Loading...</div>
  }

  return (
    <Card>
      { !loading && <CouponProductsTable/>}
    </Card>
  );
}

export default OfferProducts;
