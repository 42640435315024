import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useSelector } from 'react-redux';
import { RootState } from './store/rootState';
import { AppActions } from './modules/app/actions';
import { useAction } from './utils/hooks/action';
import LoginPage from './pages/auth';
import ProductPage from './pages/product';
import ProductsPage from './pages/products';
import FlashSellProductsPage from './pages/products/flash_sell';
import BestSellerProductsPage from './pages/products/best_seller';
import YouMayAlsoLikeProductsPage from './pages/products/you_may_also_like';
import SuspenseLoader from './components/SuspenseLoader';
import BaseLayout from './layouts/BaseLayout';
import SidebarLayout from './layouts/SidebarLayout';
import Offers from './pages/offers';
import Offer from './pages/offer';

import PropTypes from 'prop-types';

// Loader function with display name
export const Loader = (Component) => {
  const WrappedComponent = (props) => (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
  WrappedComponent.displayName = `Loader(${Component.displayName || Component.name})`;  // Adding display name
  return WrappedComponent;
};

// ProtectedRoute component with prop validation
export const ProtectedRoute = ({ children }) => {
  const { login } = useSelector((state: RootState) => state.app);
  if (!login) {
    return <Navigate to="/" replace />;
  }

  return <SidebarLayout>{children}</SidebarLayout>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired, // PropTypes validation for 'children'
};

function App() {
  const navigate = useNavigate();
  const { nav } = useSelector((state: RootState) => state.app);
  const removeNavigate = useAction(AppActions.removeNavigate);

  useEffect(() => {
    if(nav) {
      removeNavigate();
      navigate(nav, { replace: true });
    }
  }, [nav]);

  // Orders
  const PendingOrders = Loader(lazy(() => import('src/pages/orders/pending')));
  const WaitingForPaymentOrders = Loader(lazy(() => import('src/pages/orders/waiting_for_payment')));
  const ProcessingOrders = Loader(lazy(() => import('src/pages/orders/processing')));
  const CompletedOrders = Loader(lazy(() => import('src/pages/orders/completed')));
  const RejectedOrders = Loader(lazy(() => import('src/pages/orders/rejected')));
  const RefundedOrders = Loader(lazy(() => import('src/pages/orders/refunded')));
  const FailedOrders = Loader(lazy(() => import('src/pages/orders/failed')));
  const CancelledOrders = Loader(lazy(() => import('src/pages/orders/cancelled')));
  const Order = Loader(lazy(() => import('src/pages/order')));

  // Products
  const Products = Loader(lazy(() => import('src/pages/products')));
  const Product = Loader(lazy(() => import('src/pages/product')));
  const UnAvailableProducts = Loader(lazy(() => import('src/pages/products/unavailable')));
  const BestSellerProducts = Loader(lazy(() => import('src/pages/products/best_seller')));
  const YouMayAlsoLikeProducts = Loader(lazy(() => import('src/pages/products/you_may_also_like')));

  // Coupons
  const Coupons = Loader(lazy(() => import('src/pages/coupons')));
  const Coupon = Loader(lazy(() => import('src/pages/coupon')));

  // Other pages
  const Sliders = Loader(lazy(() => import('src/pages/sliders')));
  const Users = Loader(lazy(() => import('src/pages/users')));
  const CreateUser = Loader(lazy(() => import('src/pages/users/create')));
  const Categories = Loader(lazy(() => import('src/pages/categories')));
  const Brands = Loader(lazy(() => import('src/pages/brands')));
  const Attributes = Loader(lazy(() => import('src/pages/attributes')));

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />

      <Route path="/sliders" element={<Sliders />} />
      <Route path="/orders" element={<Navigate to="waiting" replace />} />
      <Route path="/orders/:id" element={<ProtectedRoute><Order /></ProtectedRoute>} />
      <Route path="/orders/waiting" element={<ProtectedRoute><WaitingForPaymentOrders /></ProtectedRoute>} />
      <Route path="/orders/pending" element={<ProtectedRoute><PendingOrders /></ProtectedRoute>} />
      <Route path="/orders/processing" element={<ProtectedRoute><ProcessingOrders /></ProtectedRoute>} />
      <Route path="/orders/completed" element={<ProtectedRoute><CompletedOrders /></ProtectedRoute>} />
      <Route path="/orders/failed" element={<ProtectedRoute><FailedOrders /></ProtectedRoute>} />
      <Route path="/orders/refunded" element={<ProtectedRoute><RefundedOrders /></ProtectedRoute>} />
      <Route path="/orders/rejected" element={<ProtectedRoute><RejectedOrders /></ProtectedRoute>} />
      <Route path="/orders/cancelled" element={<ProtectedRoute><CancelledOrders /></ProtectedRoute>} />

      <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>} />
      <Route path="/product/:id" element={<ProtectedRoute><Product /></ProtectedRoute>} />
      <Route path="/products/unavailable" element={<ProtectedRoute><UnAvailableProducts /></ProtectedRoute>} />
      <Route path="/products/best_sell" element={<ProtectedRoute><BestSellerProductsPage /></ProtectedRoute>} />
      <Route path="/products/you_may_also_like" element={<ProtectedRoute><YouMayAlsoLikeProductsPage /></ProtectedRoute>} />
      <Route path="/products/flash_sell" element={<ProtectedRoute><FlashSellProductsPage /></ProtectedRoute>} />

      <Route path="/coupons" element={<ProtectedRoute><Coupons /></ProtectedRoute>} />
      <Route path="/coupons/:id" element={<ProtectedRoute><Coupon /></ProtectedRoute>} />

      <Route path="/offers" element={<ProtectedRoute><Offers /></ProtectedRoute>} />
      <Route path="/offers/:id" element={<ProtectedRoute><Offer /></ProtectedRoute>} />

      <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
      <Route path="/categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
      <Route path="/brands" element={<ProtectedRoute><Brands /></ProtectedRoute>} />
      <Route path="/attributes" element={<ProtectedRoute><Attributes /></ProtectedRoute>} />
      <Route path="/create_user" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />
    </Routes>
  );
}

export default App;
