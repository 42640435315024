import React, { useEffect } from 'react'; // Make sure React is imported for JSX

import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';

import ProductsHeader from '../components/ProductsHeader';
import Products from '../components/Products';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { useAction } from 'src/utils/hooks/action';
import { ProductsActions } from '../module/actions';

function YouMayAlsoLikeProductsPage() {
  const { loading } = useSelector((state: RootState) => state.orders);
  const productsAction = useAction(ProductsActions.products.request);

  useEffect(() => {
    productsAction({ page: 0, min_price: 0, is_you_may_also_like: 1 });
  }, [productsAction]);

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <Helmet>
        <title>Products - Best Seller</title>
      </Helmet>
      <ProductsHeader title={'Unavailable Products'} description="" />
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Products />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default YouMayAlsoLikeProductsPage;
