import { Api } from './Api';
import { getUrl } from '../../utils/helper';
import { ASSIGN_CATEGORY_TO_COUPON_URL, ASSIGN_CATEGORY_TO_OFFER_URL, ASSIGN_PRODUCT_TO_COUPON_URL, ASSIGN_PRODUCT_TO_OFFER_URL, COUPONS_URL, COUPON_URL, CREATE_COUPON_URL, CREATE_OFFER_URL, OFFERS_URL, OFFER_URL, PRODUCTS_URL, PRODUCT_URL } from '../../configs';
import { CouponPayload } from '../payload/copoun';
import { CreateCouponPayload } from '../payload/create_coupon';
import { CreateOfferPayload } from '../payload/create_offer';


export class OffersApis {
  static offers(): ReturnType<typeof Api.get> {
    return Api.get(OFFERS_URL);
  }

  static offer(payload: CouponPayload): ReturnType<typeof Api.get> {
    return Api.get(OFFER_URL, payload);
  }

  static create(payload: CreateOfferPayload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_OFFER_URL, payload);
  }

  static assignCategory(payload: any): ReturnType<typeof Api.post> {
    return Api.post(ASSIGN_CATEGORY_TO_OFFER_URL, payload);
  }

  static assignProduct(payload: any): ReturnType<typeof Api.post> {
    return Api.post(ASSIGN_PRODUCT_TO_OFFER_URL, payload);
  }
}