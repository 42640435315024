import { Api } from './Api';
import {  getUrl } from '../../utils/helper';
import { ATTRIBUTES_URL, CREATE_ATTRIBUTE_URL, CREATE_ATTRIBUTE_VALUE_URL } from '../../configs';

export class AttributesApis {
  static attributes(): ReturnType<typeof Api.get> {
    return Api.get(ATTRIBUTES_URL);
  }
  
  static createAttributes(payload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_ATTRIBUTE_URL, payload);
  }

  static createAttributeValues(payload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_ATTRIBUTE_VALUE_URL, payload);
  }
}
